.pyEditor {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 2px;
  width: 100%;
}
.jsEditor {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 2px;
}
